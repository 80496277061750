// コンテンツの表示/非表示を切り替えます.
//
// <!-- simply pattern ->
// <div data-controller="toggle">
//   <div data-target="toggle.handler" data-action="click->toggle#toggle">Click Me!</div>
//   <div data-target="toggle.container">
//     <h1>Hello World!</h1>
//   </div>
// </div>
//
// <!-- 初期状態で展開する場合 ->
// <div data-controller="toggle" data-toggle-active="true">
//   <div data-target="toggle.handler" data-action="click->toggle#toggle">Click Me!</div>
//   <div data-target="toggle.container">
//     <h1>Hello World!</h1>
//   </div>
// </div>

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'handler', 'container' ]

  connect() {
    this.show()
  }

  toggle() {
    this.active = !this.active
  }

  show() {
    if (this.active) {
      $(this.handlerTargets).addClass('active')
      $(this.containerTargets).fadeIn()
    } else {
      $(this.handlerTargets).removeClass('active')
      $(this.containerTargets).fadeOut()
    }
  }

  get active() {
    return this.data.get('active') == 'true'
  }

  set active(value) {
    this.data.set('active', !!value)
    this.show()
  }
}
