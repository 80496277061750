// 初期状態で一部省略されているコンテンツを表現します.
// more#more アクションを実行することで省略されているコンテンツを全て表示します.
//
// <!-- simply pattern ->
// <div data-controller="more" data-target="more.container">
//   <div>
//     ...
//   </div>
//   <span data-target="more.handler" data-action="click->more#more">すべて表示</span>
// </div>
//
// <!-- 初期状態で展開する場合 (data-more-show="true") ->
// <div data-controller="more" data-target="more.container" data-more-show="true">
//   <div>
//     ...
//   </div>
//   <span data-target="more.handler" data-action="click->more#more">すべて表示</span>
// </div>

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'handler', 'container' ]

  connect() {
    this.render()
  }

  more() {
    this.show = true
  }

  render() {
    if (this.show) {
      this.handlerTarget.classList.add('d-none')
      this.containerTarget.classList.remove('-area-hidden')
    } else {
      this.handlerTarget.classList.remove('d-none')
      this.containerTarget.classList.add('-area-hidden')
    }
  }

  get show() {
    return this.data.get('show') == 'true'
  }

  set show(value) {
    this.data.set('show', !!value)
    this.render()
  }
}
