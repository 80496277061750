// Javascript による form 全般に関する挙動を調整します.
//
// <form data-controller="adminform">
//   <!-- textarea は入力行数に応じて自動的に拡張します ->
//   <textarea data-target="adminform.textarea" data-action="input->adminform#autosize">
//     foo
//     bar
//   </textarea>
//
//   <!-- change 発行時に form を submit します -->
//   <select data-action="change->adminform#submit">
//     <option>foo</option>
//     <option>bar</option>
//   </select>
// </form>

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'textarea' ]

  connect() {
    const domReady = (f) => { /in/.test(document.readyState) ? setTimeout(domReady, 16, f) : f() }
    const autosizeAll = this.autosizeAll.bind(this)
    domReady(autosizeAll)
  }

  autosizeAll() {
    this.textareaTargets.forEach(t => this.autosize(null, t))
  }

  autosize(e, control = null) {
    const target = control ? control : e.currentTarget
    target.style.height = 'auto'
    target.style.height = target.scrollHeight + 10 + 'px'
  }

  submit(e) {
    e.preventDefault()
    e.currentTarget.form.submit()
  }
}
