// イベントをきっかけとして指定された URL に移動します.
//
// <div data-controller="navigate" data-navigate-url="https://google.co.jp/">
//   <div data-action="click->navigate#visit">Click Me!</div>
// </div>

import { Controller } from 'stimulus'

export default class extends Controller {
  visit(_event) {
    location.href = this.url
  }

  get url() {
    return this.data.get('url')
  }
}
